<template>
  <div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="项目资金审计原则">
            <a-radio-group
              v-decorator="[
                'isAudit',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-radio :value="1"> 符合 </a-radio>
              <a-radio :value="0"> 不符合 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="工程名称"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            class="default-highlight"
          >
            <a-input :disabled="true" :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="调整金额(元)">
            <a-input-number
              :formatter="$inputNumberFormat"
              style="width: 100%"
              v-decorator="[
                'modifyAmount',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            ></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="调整利润(元)">
            <a-input-number
              :formatter="$inputNumberFormat"
              style="width: 100%"
              v-decorator="[
                'modifyProfit',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            ></a-input-number>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="采购计划调整概述"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-textarea
              :auto-size="{ minRows: 5 }"
              v-decorator="[
                'modifyPlanDesc',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
              placeholder="(调整原因、涉及金额、是否满足审计结算要求等)"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <span slot="label" class="">附件</span>
            <div class="link-list">
              <a-checkbox-group
                :value="selectedFileList"
                @change="onSelectFile"
                v-if="fileList.length !== 0"
              >
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                      item.name
                    }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color: #ccc" v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color: #ccc" v-else>
                《总承包项目采购计划调整申请表》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="相关部门">
            <EmployeeSelector
              title="选择联系人"
              :single="false"
              @change="selectedUser"
              :value="selectedUserList.length ? selectedUserList : []"
            >
              <a-button block style="text-align: left; width: auto">
                <div v-if="selectedUserList.length">
                  <span v-for="item in selectedUserList" :key="item.userId">{{
                    `${item.name} `
                  }}</span>
                </div>
                <div v-else style="color: #1890ff">选择联系人</div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <div v-if="!isView">
            <a-button htmlType="submit" type="primary" v-if="!id"
              >提交</a-button
            >
            <a-button htmlType="submit" type="primary" v-else>修改</a-button>
          </div>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    EmployeeSelector,
    FileUpload,
  },
  data() {
    return {
      stage: "epc_purchase_plan_mod",

      id: "", //当前表单的id
      hid: "", //历史记录id
      isView: false,
      form: this.$form.createForm(this),
      selectedUserList: [],

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDataDict"]),

    stageName() {
      return this.findSingleDataDict("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id || "";
    this.hid = hid || "";

    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.selectedUserList = res.relatedEmployee
            ? JSON.parse(res.relatedEmployee)
            : [];
          this.form.setFieldsValue({
            isAudit: res.isAudit,
            modifyAmount: res.modifyAmount,
            modifyProfit: res.modifyProfit,
            modifyPlanDesc: res.modifyPlanDesc,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    selectedUser(value) {
      this.selectedUserList = value;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          console.log(values, this.fileList);
          values.isAudit = values.isAudit - 0; // 转成数字
          let selectedUserList = this.selectedUserList.map((item) => {
            return {
              userId: item.userId,
              name: item.name,
            };
          });
          console.log(selectedUserList);

          if (!this.id) {
            add({
              ...values,
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              type: this.stage,
              relatedEmployee: JSON.stringify(selectedUserList),
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
              this.selectedUserList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.msg.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
