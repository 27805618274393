<template>
  <div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="工程名称"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            class="default-highlight"
          >
            <a-input :disabled="true" :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="采购总金额(元)">
            <a-input-number
              :formatter="$inputNumberFormat"
              style="width: 100%"
              v-decorator="[
                'totalAmount',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            ></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="采购利润(元)">
            <a-input-number
              :formatter="$inputNumberFormat"
              style="width: 100%"
              v-decorator="[
                'totalProfit',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            ></a-input-number>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="总计划概述"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-textarea
              :auto-size="{ minRows: 5 }"
              v-decorator="[
                'planDesc',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
              placeholder="(总计划金额、采购工作目标、采购批次、标的分类等)"
            />
          </a-form-item>
        </a-col>

        <a-col
          :lg="24"
          :md="24"
          :sm="24"
          :key="index"
          v-for="(item, index) in contentList"
        >
          <a-form-item
            label="采购计划调整概述"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-textarea :auto-size="{ minRows: 5 }" :value="item" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <span slot="label" class="">附件</span>
            <div
              class="link-list"
              v-decorator="[
                'file',
                { rules: [{ required: true, message: '请上传附件' }] },
              ]"
            >
              <a-checkbox-group
                :value="selectedFileList"
                @change="onSelectFile"
                v-if="fileList.length !== 0"
              >
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                      item.name
                    }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color: #ccc" v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color: #ccc; line-height: 1.5" v-else>
                《标签经管会纪要》<br />
                《采购总计划》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <div v-if="!isView">
            <a-button htmlType="submit" type="primary" v-if="!isView"
              >提交</a-button
            >
          </div>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import {
  add,
  fetchDetailByPid,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      stage: "epc_purchase_plan_master",

      id: "", //当前表单的id
      hid: "", //历史记录id
      pid: "", //项目id
      isView: false,
      form: this.$form.createForm(this),

      contentList: [], //总计划调整

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },

  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.hid = hid || "";

    this.isView = Boolean(isView) || false;

    this.getDetailByPid();
  },
  methods: {
    getDetailByPid() {
      fetchDetailByPid({ id: this.pid, stage: this.stage })
        .then((res) => {
          if (!res.id) return;
          this.id = res.id;
          this.form.setFieldsValue({
            totalAmount: res.totalAmount,
            totalProfit: res.totalProfit,
            planDesc: res.planDesc,
          });
          this.contentList = res.modifyPlanDesc?.split("\\n").slice(1) || [];
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
            this.form.setFieldsValue({
              file: res.attachmentList,
            });
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          delete values.file;
          add({
            ...values,
            pid: this.msg.pid,
            projectName: this.msg.projectName,
            designCode: this.msg.designCode,
            attachments: this.fileList,
            type: this.stage,
          }).then(() => {
            this.getDetailByPid();
          });
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
