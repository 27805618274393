var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.designCode}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.projectName}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"采购总金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'totalAmount',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'totalAmount',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"采购利润(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'totalProfit',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'totalProfit',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"总计划概述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'planDesc',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'planDesc',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 5 },"placeholder":"(总计划金额、采购工作目标、采购批次、标的分类等)"}})],1)],1),_vm._l((_vm.contentList),function(item,index){return _c('a-col',{key:index,attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"采购计划调整概述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{attrs:{"auto-size":{ minRows: 5 },"value":item}})],1)],1)}),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'file',
              { rules: [{ required: true, message: '请上传附件' }] },
            ]),expression:"[\n              'file',\n              { rules: [{ required: true, message: '请上传附件' }] },\n            ]"}],staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc","line-height":"1.5"}},[_vm._v(" 《标签经管会纪要》"),_c('br'),_vm._v(" 《采购总计划》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],2),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('div',[(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }